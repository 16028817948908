import React from "react";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "./DrawerHeader";
import { Box } from "@mui/material";

export default function InviteFriendsDrawer({
  openInviteFriendsDrawer,
  toggleInviteFriendsDrawer,
}) {
  return (
    <Drawer anchor="left" open={openInviteFriendsDrawer}>
      <DrawerHeader
        menuName="Freunde einladen"
        toggleDrawer={toggleInviteFriendsDrawer}
      ></DrawerHeader>
      <Box
        sx={{
          maxWidth: 450,
          width: "100vw",
          height: "100vh",
          backgroundColor: "primary.light",
        }}
        role="presentation"
      ></Box>
    </Drawer>
  );
}
