import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import EmptyState from "./EmptyState";
import { Container } from "@mui/material";

export default function FavoriteList({
  action,
  data,
  favoriteCards,
  setFavoriteCards,
}) {
  const deleteEntry = (clickedValue) => {
    const updatedFavoriteCards = favoriteCards.filter(
      (card) => card !== clickedValue
    ); // Remove the clicked value
    setFavoriteCards(updatedFavoriteCards);
    localStorage.setItem("favoriteCards", JSON.stringify(updatedFavoriteCards)); // Persist updated array to localStorage
  };
  const getFirstTwoLetters = (shortenPls) => {
    return shortenPls.slice(0, 2);
  };

  return (
    <>
      <Container sx={{ mt: 4 }}>
        {favoriteCards.length > 0 ? (
          <Grid item xs={12} md={6}>
            <List>
              {favoriteCards.map((currentValue, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      onClick={() => deleteEntry(currentValue)}
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{ backgroundColor: "primary.text" }}
                      alt={getFirstTwoLetters(currentValue)}
                    >
                      {getFirstTwoLetters(currentValue)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={currentValue} />
                </ListItem>
              ))}
            </List>
          </Grid>
        ) : (
          <EmptyState emptyIcon={""} whereEmpty={"favoriteList"} />
        )}
      </Container>
    </>
  );
}
