import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import FeedCard from "./FeedCard";
import feedCardsData from "./data/feedCards.json";
import SortFab from "./SortFab";
import EmptyState from "./EmptyState";

export default function Feed({
  setIgnoredCards,
  ignoredCards,
  setFavoriteCards,
  favoriteCards,
  handleFavoriteCard,
  whereEmpty,
  setWhereEmpty,
  toggleWhereEmpty,
  variant,
}) {
  const [feedCards, setFeedCards] = useState(feedCardsData); // Store data in state
  const [sortOption, setSortOption] = useState("relevance"); // Store selected sort option
  const [isLoading, setLoading] = useState(false);

  // Reusable function to toggle loading state
  const toggleLoading = () => {
    setLoading(true);

    // Set loading to false after 1 second
    setTimeout(() => {
      setLoading(false);
    }, 1250);
  };

  // Load ignored cards from localStorage on component mount
  useEffect(() => {
    const storedIgnoredCards =
      JSON.parse(localStorage.getItem("ignoredCards")) || [];
    setIgnoredCards(storedIgnoredCards);
  }, []);

  // Store all cards in localStorage (if needed)
  useEffect(() => {
    localStorage.setItem("allCards", JSON.stringify(feedCards));
  }, [feedCards]);

  const checkIfFavoriteCard = (businessName) => {
    if (favoriteCards.includes(businessName)) {
      return true;
      // Optionally, remove it or show a message
    } else {
      return false;
    }
  };

  // Function to handle sorting based on the selected option
  const handleSort = (option) => {
    setSortOption(option); // Update the sort option state

    let sortedCards = [...feedCards];

    switch (option) {
      case "hourlySalary":
        sortedCards = sortedCards.sort(
          (a, b) =>
            parseFloat(b.hourlySalary.replace(",", ".")) -
            parseFloat(a.hourlySalary.replace(",", "."))
        );
        break;
      case "hourlySalary-asc":
        sortedCards = sortedCards.sort(
          (a, b) =>
            parseFloat(a.hourlySalary.replace(",", ".")) -
            parseFloat(b.hourlySalary.replace(",", "."))
        );
        break;
      case "distance":
        sortedCards = sortedCards.sort(
          (a, b) => parseFloat(a.distance) - parseFloat(b.distance)
        );
        break;
      case "distance-desc":
        sortedCards = sortedCards.sort(
          (a, b) => parseFloat(b.distance) - parseFloat(a.distance)
        );
        break;
      default:
        break;
    }

    setFeedCards(sortedCards); // Update the state with the sorted array
    localStorage.setItem("sortedCards", JSON.stringify(sortedCards));
  };

  // Function to handle ignoring a card
  const handleIgnoreCard = (businessName) => {
    const updatedIgnoredCards = [...ignoredCards, businessName]; // Add the card name to the ignored list
    setIgnoredCards(updatedIgnoredCards);
    localStorage.setItem("ignoredCards", JSON.stringify(updatedIgnoredCards)); // Persist to localStorage
  };

  // Filter out ignored cards before rendering
  const visibleFeedCards = feedCards.filter(
    (card) => !ignoredCards.includes(card.businessName) // Filter out ignored cards by businessName
  );
  let visibleTabCards = [];
  console.log(visibleFeedCards);
  console.log(favoriteCards);
  if (variant == "homeTab") {
    visibleTabCards = visibleFeedCards;
  }
  if (variant == "favoriteTab") {
    visibleTabCards = visibleFeedCards.filter((card) =>
      favoriteCards.includes(card.businessName)
    );
  }

  return (
    <>
      <SortFab
        onSortChange={handleSort}
        selectedOption={sortOption}
        loadingToggleFunction={toggleLoading}
      ></SortFab>
      <Stack
        className="feed"
        direction="column"
        spacing={2}
        sx={{
          justifyContent: "flex-start",
          alignItems: "stretch",
          mt: 2,
          mb: 10,
        }}
      >
        {visibleTabCards.length > 0 ? (
          visibleTabCards.map(
            (
              {
                businessName,
                featuredImage,
                format,
                hourlySalary,
                location,
                distance,
              },
              index
            ) => (
              <FeedCard
                key={index}
                className={`${index}--feedCardCounter`}
                loadingState={isLoading}
                businessName={businessName}
                featuredImage={featuredImage}
                format={format}
                hourlySalary={hourlySalary}
                location={location}
                distance={distance}
                onIgnore={handleIgnoreCard}
                onFavorite={handleFavoriteCard}
                favoriteCards={favoriteCards}
                setFavoriteCards={setFavoriteCards}
                checkIfFavoriteCard={checkIfFavoriteCard(businessName)}
              ></FeedCard>
            )
          )
        ) : (
          <EmptyState whereEmpty={"homeTab"} />
        )}
      </Stack>
    </>
  );
}
