import React from "react";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "./DrawerHeader";
import { Box } from "@mui/material";

export default function MessagesDrawer({
  openMessagesDrawer,
  toggleMessagesDrawer,
}) {
  return (
    <Drawer anchor="left" open={openMessagesDrawer}>
      <DrawerHeader
        menuName="Nachrichten"
        toggleDrawer={toggleMessagesDrawer}
      ></DrawerHeader>
      <Box
        sx={{
          maxWidth: 450,
          width: "100vw",
          height: "100vh",
          backgroundColor: "primary.light",
        }}
        role="presentation"
      ></Box>
    </Drawer>
  );
}
