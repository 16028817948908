import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import EmptyIcon from "./EmptyIcon";

export default function EmptyState({ whereEmpty }) {
  console.log(whereEmpty);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        style={{ height: "calc(100vh - 32px)" }}
      >
        <EmptyIcon></EmptyIcon>
        <Typography
          sx={{ mb: 2, mt: 4, textAlign: "center", lineHeight: "1.5" }}
          variant="h4"
        >
          Keiner da..
        </Typography>
        <Typography
          sx={{ textAlign: "center", lineHeight: "1.5" }}
          variant="subtitle1"
        >
          {whereEmpty === "homeTab" ? (
            <>
              Wir haben keine Praxis gefunden, die Deinen Ansprüchen genügt.
              <br />
              Diva.
            </>
          ) : whereEmpty === "favoriteTab" ? (
            <>
              Du hast noch keine Praxis beblockt.
              <br />
              Nett von Dir.
            </>
          ) : whereEmpty === "favoriteList" ? (
            <>
              Du hast noch keine Praxis zu den Favoriten hinzugefügt.
              <br />
              Alle doof?
            </>
          ) : whereEmpty === "blockedList" ? (
            <>
              Du hast noch keine Praxis geblockt.
              <br />
              Nett von Dir.
            </>
          ) : (
            "itsFalse"
          )}
        </Typography>
      </Box>
    </>
  );
}
