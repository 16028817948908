import React, { useState, useEffect } from "react";
import {
  Euro,
  LocationCity,
  NearMe,
  Payment,
  Favorite,
  FavoriteBorder,
  Close,
  MoreVert,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import MoreOptionsMenu from "./MoreOptionsMenu";

export default function FeedCard(
  {
    businessName,
    featuredImage,
    format,
    hourlySalary,
    location,
    distance,
    className,
    loadingState,
    onIgnore,
    onFavorite,
    favoriteCards,
    setFavoriteCards,
    checkIfFavoriteCard,
  },
  props
) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isFavorited, setIsFavorited] = React.useState(false);
  const toggleSetIsFavorited = (checkIfFavoriteCard) => {
    setIsFavorited(checkIfFavoriteCard);
  };
  const handleFavoriteClick = () => {
    setIsFavorited(!isFavorited); // Toggle the state
    setIsOpen(!isFavorited);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      {loadingState ? (
        <Skeleton
          variant="rounded"
          sx={{ aspectRatio: "4/4", height: "inherit" }}
        />
      ) : (
        <Paper
          className={`${className} feedCard`}
          elevation={5}
          sx={{
            p: 3,

            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 75%), linear-gradient(to right, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.4) 90%), url(${featuredImage})`,
            backgroundSize: "cover",
            //   width: "100%",
            //   height: "100vh",
            aspectRatio: format,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-end",
          }}
        >
          <Box>
            <Stack
              direction="column"
              spacing={1}
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-end",
                my: 0.75,
                position: "absolute",
                top: 10,
                right: 10,
              }}
            >
              <IconButton
                sx={{
                  color: "var(--mui-palette-rainbow-red)",
                }}
                onClick={handleFavoriteClick}
                size=""
                backgroundColor="red"
              >
                {!checkIfFavoriteCard ? (
                  <FavoriteBorder
                    fontSize="inherit"
                    onClick={() => {
                      onFavorite(businessName, false);
                    }}
                  />
                ) : (
                  <Favorite
                    fontSize="inherit"
                    onClick={() => {
                      onFavorite(businessName, true);
                    }}
                  />
                )}
              </IconButton>
              <Snackbar
                open={isOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Praxis in Favoriten gespeichert"
                action={action}
              />

              <MoreOptionsMenu
                businessName={businessName}
                onIgnore={onIgnore}
              />
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-end",
                my: 0.75,
              }}
            >
              <Chip
                sx={{ backgroundColor: "white" }}
                label={location}
                icon={<LocationCity />}
              />

              <Chip
                sx={{ backgroundColor: "white" }}
                label={`${distance} km`}
                icon={<NearMe />}
              />

              <Chip
                sx={{ backgroundColor: "white" }}
                label={`${hourlySalary}/h`}
                icon={<Euro />}
              />
            </Stack>
            <Typography
              sx={{
                zIndex: 1,
                color: "white",
                lineHeight: 1,
                mt: 1.5,
                letterSpacing: 1,
                wordBreak: "break-word",
              }}
              variant="h3"
            >
              {businessName}
            </Typography>
          </Box>
        </Paper>
      )}
    </>
  );
}
