import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TemporaryDrawer from "./TemporaryDrawer.js";
import SvgLogoLetter from "./SvgLogoLetter.js";
import { IconButton, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";

export default function SimpleTopNavigation({
  setIgnoredCards,
  ignoredCards,
  favoriteCards,
  setFavoriteCards,
}) {
  return (
    <>
      <Box sx={{ height: 44 }}></Box>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          py: 1,
          px: 1.5,

          backgroundColor: "primary.light",
        }}
        square
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <TemporaryDrawer
            setIgnoredCards={setIgnoredCards}
            ignoredCards={ignoredCards}
            favoriteCards={favoriteCards}
            setFavoriteCards={setFavoriteCards}
          ></TemporaryDrawer>
          <SvgLogoLetter></SvgLogoLetter>
          <Tooltip title="Account settings">
            <IconButton size="small" sx={{ p: 0 }}>
              <Avatar
                alt="TG"
                src="./static/images/avatar/1.jpg"
                sx={{ width: 28, height: 28 }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
}
