import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Fab from "@mui/material/Fab";
import { Close, Tune } from "@mui/icons-material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { IconButton, Snackbar } from "@mui/material";

export default function SortFab({
  onSortChange,
  selectedOption,
  loadingToggleFunction,
}) {
  const [snackbarMessage, setSnackbarMessage] = React.useState(""); // Message to display in Snackbar

  // Function to determine the message based on the selected option
  const getSnackbarMessage = (give) => {
    console.log(give);
    switch (give) {
      case "relevance":
        return "Nach Relevanz sortiert.";
      case "hourlySalary":
        return "Nach Stundenlohn sortiert.";
      case "distance":
        return "Nach Entfernung sortiert.";
      default:
        return ""; // Default message if no option matches
    }
  };
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const handleRadioChange = (event) => {
    onSortChange(event.target.value); // Trigger sorting in the parent component
    handleClose();
    setSnackbarMessage(getSnackbarMessage(event.target.value));
    loadingToggleFunction();
    console.log(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);

    console.log(reason);

    if (reason !== "backdropClick") {
      console.log("bg clicked");
      setSnackbarOpen(true);
    }
  };
  const snackbarHandleClose = (event, reason) => {
    console.log(reason);
    setSnackbarOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={snackbarHandleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </>
  );
  return (
    <>
      <Box
        sx={{
          "& > :not(style)": { m: 1 },
          position: "fixed",
          bottom: 60,
          right: 15,
          zIndex: 1,
        }}
      >
        <Fab
          size="medium"
          color="primary"
          aria-label="add"
          onClick={handleClickOpen}
        >
          <Tune></Tune>
        </Fab>
      </Box>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Jobangebote sortieren</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Wonach möchtest Du Deine Jobangebote sortieren?
          </DialogContentText>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Sortieren nach
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="relevance"
              name="radio-buttons-group"
              value={selectedOption}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="relevance"
                control={<Radio />}
                label="Relevanz"
              />
              <FormControlLabel
                value="hourlySalary"
                control={<Radio />}
                label="Stundenlohn"
              />
              <FormControlLabel
                value="distance"
                control={<Radio />}
                label="Entfernung"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={snackbarHandleClose}
        message={snackbarMessage}
        action={action}
      />
    </>
  );
}
