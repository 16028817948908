import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { purple, indigo, lightGreen, cyan } from "@mui/material/colors";

export default function SvgLogoLetter() {
  return (
    <SvgIcon style={{ fill: cyan["A700"] }}>
      <svg
        x="0px"
        y="0px"
        width="267.6px"
        height="370.6px"
        viewBox="0 0 267.6 370.6"
      >
        <g>
          <path
            d="M67.1,370.6H0V9.5h16.1L42.7,41c6-5.7,12.6-11.1,19.8-16.1c7.2-5,14.6-9.4,22.3-13.1c7.7-3.7,15.7-6.5,24-8.7
		C117.2,1.1,125.6,0,134,0c18.4,0,35.7,3.3,51.9,9.9c16.2,6.6,30.4,15.9,42.5,28c12.1,12,21.7,26.6,28.7,43.6
		c7,17,10.5,35.9,10.5,56.5c0,21.5-3.5,40.8-10.5,58c-7,17.2-16.6,31.7-28.7,43.7c-12.1,12-26.3,21.1-42.5,27.5
		c-16.2,6.3-33.5,9.5-51.9,9.5c-5.9,0-11.7-0.7-17.5-2c-5.8-1.3-11.5-3.1-17.1-5.2s-11.2-4.7-16.6-7.4c-5.5-2.8-10.7-5.6-15.7-8.5
		V370.6z M200.7,137.9c0-11.1-1.8-21-5.2-29.8c-3.5-8.8-8.3-16.2-14.3-22.3c-6-6.1-13.1-10.8-21.2-14c-8.1-3.3-16.8-4.9-25.9-4.9
		c-9.1,0-17.7,1.6-25.8,4.9c-8.1,3.3-15.1,7.9-21.1,14c-6,6.1-10.8,13.5-14.3,22.3c-3.5,8.8-5.2,18.7-5.2,29.8
		c0,10.6,1.7,20.3,5.2,29.1c3.5,8.8,8.3,16.3,14.3,22.5c6,6.2,13.1,11,21.1,14.4s16.6,5.1,25.8,5.1c9.1,0,17.7-1.7,25.9-5.1
		c8.1-3.4,15.2-8.2,21.2-14.4c6-6.2,10.8-13.7,14.3-22.5C198.9,158.2,200.7,148.5,200.7,137.9z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
