import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import "./App.css";
import Feed from "./Feed.js";

import SimpleBottomNavigation from "./SimpleBottomNavigation.js";
import SimpleFab from "./SimpleFab.js";
import SimpleTopNavigation from "./SimpleTopNavigation.js";

function App() {
  const [ignoredCards, setIgnoredCards] = useState([]);
  const [favoriteCards, setFavoriteCards] = useState([]);
  const [currentTab, setCurrentTab] = useState(["homeTab"]);
  const [whereEmpty, setWhereEmpty] = useState([false]);
  useEffect(() => {
    const storedFavoriteCards =
      JSON.parse(localStorage.getItem("favoriteCards")) || [];
    setFavoriteCards(storedFavoriteCards);
    console.log("App.js setFavoriteCards();");
    console.log(favoriteCards);
  }, []);
  const handleFavoriteCard = (clickedFavorite, isFavorite) => {
    let updatedFavoriteCards;
    if (isFavorite) {
      updatedFavoriteCards = favoriteCards.filter(
        (card) => card !== clickedFavorite
      ); // Remove the clicked Business
    } else {
      updatedFavoriteCards = [...favoriteCards, clickedFavorite]; // Add the card name to the ignored list
    }

    setFavoriteCards(updatedFavoriteCards);
    localStorage.setItem("favoriteCards", JSON.stringify(updatedFavoriteCards)); // Persist updated array to localStorage
  };

  const toggleCurrentTab = (clickedTab) => {
    setCurrentTab(clickedTab);
  };
  const toggleWhereEmpty = (emptinessDetected) => {
    setWhereEmpty(emptinessDetected);
  };

  return (
    <>
      <Container>
        <SimpleTopNavigation
          setIgnoredCards={setIgnoredCards}
          ignoredCards={ignoredCards}
          setFavoriteCards={setFavoriteCards}
          favoriteCards={favoriteCards}
          handleFavoriteCard={handleFavoriteCard}
        ></SimpleTopNavigation>
        <Feed
          variant={currentTab}
          setIgnoredCards={setIgnoredCards}
          ignoredCards={ignoredCards}
          setFavoriteCards={setFavoriteCards}
          favoriteCards={favoriteCards}
          handleFavoriteCard={handleFavoriteCard}
          whereEmpty={whereEmpty}
          setWhereEmpty={setWhereEmpty}
          toggleWhereEmpty={toggleWhereEmpty}
        ></Feed>
        <SimpleBottomNavigation
          toggleCurrentTab={toggleCurrentTab}
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
        ></SimpleBottomNavigation>
      </Container>
    </>
  );
}

export default App;
