import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "./DrawerHeader";
import { Box } from "@mui/material";
import FavoriteList from "./FavoriteList";

export default function FavoriteDrawer({
  openFavoriteDrawer,
  toggleFavoriteDrawer,
  setFavoriteCards,
  favoriteCards,
}) {
  return (
    <Drawer anchor="left" open={openFavoriteDrawer}>
      <DrawerHeader
        menuName="Geblockt"
        toggleDrawer={toggleFavoriteDrawer}
      ></DrawerHeader>
      <Box
        sx={{
          maxWidth: 450,
          width: "100vw",
          height: "100vh",
          backgroundColor: "primary.light",
        }}
        role="presentation"
      >
        <FavoriteList
          data="ignoredCards"
          favoriteCards={favoriteCards}
          setFavoriteCards={setFavoriteCards}
        ></FavoriteList>
      </Box>
    </Drawer>
  );
}
