import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { purple, indigo, lightGreen, cyan } from "@mui/material/colors";

// const primary = lightGreen[300];
// const primary = indigo[300];
// const primary = cyan[100];
// const primary = cyan[200];
// const primary = cyan[300];
// const primary = cyan[400];
const primary = cyan["A700"];
const primaryLight = cyan["50"];
const primaryDark = cyan["700"];
const accent = purple["A200"];

const rainbow = {
  red: "#f73378",
  black: "#222222",
};

const theme = createTheme({
  palette: {
    primary: {
      light: primaryLight,
      main: primary,
      dark: primaryDark,
      contrastText: "#fff",
    },
    secondary: {
      main: accent,
    },
    background: {
      default: primaryLight,
    },
    text: {
      primary: rainbow.black,
    },
    rainbow: {
      red: rainbow.red,
      black: rainbow.black,
    },
  },
  components: {
    MuiBottomNavigation: {
      showLabels: false,
    },
    MuiBottomNavigationAction: {
      showLabel: false,
      styleOverrides: {
        root: {
          color: rainbow.black,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-colorDefault": {
            color: rainbow.black,
            "& .MuiChip-icon": {
              color: rainbow.black,
              width: "0.8em",
              height: "0.8em",
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "40px",
        },
      },
    },
  },
  cssVariables: true,
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
