import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerHeader from "./DrawerHeader";

import {
  AccountCircle,
  Block,
  Chat,
  Favorite,
  PeopleAlt,
} from "@mui/icons-material";
import FavoriteDrawer from "./FavoriteDrawer";
import AccountDrawer from "./AccountDrawer";
import MessagesDrawer from "./MessagesDrawer";
import BlockedDrawer from "./BlockedDrawer";
import InviteFriendsDrawer from "./InviteFriends";

export default function TemporaryDrawer({
  setIgnoredCards,
  ignoredCards,
  favoriteCards,
  setFavoriteCards,
}) {
  const [open, setOpen] = React.useState(false);
  const [openFavoriteDrawer, setOpenFavoriteDrawer] = React.useState(false);
  const [openAccountDrawer, setOpenAccountDrawer] = React.useState(false);
  const [openMessagesDrawer, setOpenMessagesDrawer] = React.useState(false);
  const [openBlockedDrawer, setOpenBlockedDrawer] = React.useState(false);
  const [openInviteFriendsDrawer, setOpenInviteFriendsDrawer] =
    React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const toggleAccountDrawer = (newOpen) => () => {
    setOpenAccountDrawer(newOpen);
  };
  const toggleMessagesDrawer = (newOpen) => () => {
    setOpenMessagesDrawer(newOpen);
  };
  const toggleBlockedDrawer = (newOpen) => () => {
    setOpenBlockedDrawer(newOpen);
  };
  const toggleFavoriteDrawer = (newOpen) => () => {
    setOpenFavoriteDrawer(newOpen);
  };
  const toggleInviteFriendsDrawer = (newOpen) => () => {
    setOpenInviteFriendsDrawer(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{
        maxWidth: 450,
        width: "100vw",
        height: "100vh",
        backgroundColor: "primary.light",
      }}
      role="presentation"
    >
      <List>
        <ListItem
          sx={{ color: "rainbow.black" }}
          key="Account"
          disablePadding
          onClick={toggleAccountDrawer(true)}
        >
          <ListItemButton sx={{ color: "inherit" }}>
            <ListItemIcon sx={{ color: "inherit" }}>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItemButton>
        </ListItem>
        <ListItem
          key="Nachrichten"
          disablePadding
          onClick={toggleMessagesDrawer(true)}
        >
          <ListItemButton sx={{ color: "inherit" }}>
            <ListItemIcon sx={{ color: "inherit" }}>
              <Chat />
            </ListItemIcon>
            <ListItemText primary="Nachrichten" />
          </ListItemButton>
        </ListItem>
        <ListItem
          key="Favoriten"
          disablePadding
          onClick={toggleFavoriteDrawer(true)}
        >
          <ListItemButton sx={{ color: "inherit" }}>
            <ListItemIcon sx={{ color: "inherit" }}>
              <Favorite />
            </ListItemIcon>
            <ListItemText primary="Favoriten" />
          </ListItemButton>
        </ListItem>

        <ListItem
          key="Geblockt"
          disablePadding
          onClick={toggleBlockedDrawer(true)}
        >
          <ListItemButton sx={{ color: "inherit" }}>
            <ListItemIcon sx={{ color: "inherit" }}>
              <Block />
            </ListItemIcon>
            <ListItemText primary="Geblockt" />
          </ListItemButton>
        </ListItem>
        <ListItem
          key="Freunde einladen"
          disablePadding
          onClick={toggleInviteFriendsDrawer(true)}
        >
          <ListItemButton sx={{ color: "inherit" }}>
            <ListItemIcon sx={{ color: "inherit" }}>
              <PeopleAlt />
            </ListItemIcon>
            <ListItemText primary="Freunde einladen" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Button sx={{ minWidth: "", p: 0 }} onClick={toggleDrawer(true)}>
        <MenuIcon
          sx={{ fill: "var(--mui-palette-text-primary)" }}
          fontSize="medium"
        ></MenuIcon>
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor="left">
        <DrawerHeader
          menuName="Einstellungen"
          toggleDrawer={toggleDrawer}
        ></DrawerHeader>
        {DrawerList}
      </Drawer>
      <FavoriteDrawer
        openFavoriteDrawer={openFavoriteDrawer}
        toggleFavoriteDrawer={toggleFavoriteDrawer}
        favoriteCards={favoriteCards}
        setFavoriteCards={setFavoriteCards}
      />
      <AccountDrawer
        openAccountDrawer={openAccountDrawer}
        toggleAccountDrawer={toggleAccountDrawer}
      />
      <MessagesDrawer
        openMessagesDrawer={openMessagesDrawer}
        toggleMessagesDrawer={toggleMessagesDrawer}
      />
      <BlockedDrawer
        openBlockedDrawer={openBlockedDrawer}
        toggleBlockedDrawer={toggleBlockedDrawer}
        setIgnoredCards={setIgnoredCards}
        ignoredCards={ignoredCards}
      />
      <InviteFriendsDrawer
        openInviteFriendsDrawer={openInviteFriendsDrawer}
        toggleInviteFriendsDrawer={toggleInviteFriendsDrawer}
      />
    </>
  );
}
