import * as React from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";

export default function FixedBottomNavigation({
  toggleCurrentTab,
  setCurrentTab,
  currentTab,
}) {
  const ref = React.useRef(null);

  return (
    <Box ref={ref}>
      <CssBaseline />
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        elevation={3}
        square
      >
        <BottomNavigation
          sx={{ backgroundColor: "primary.light", height: 50 }}
          value={currentTab}
          showLabels
          onChange={(event, newValue) => {
            toggleCurrentTab(newValue);
            console.log(event.target);
          }}
        >
          <BottomNavigationAction
            label="Start"
            value="homeTab"
            icon={currentTab == "homeTab" ? <HomeIcon /> : <HomeOutlinedIcon />}
          />
          <BottomNavigationAction
            label="Suchen"
            value="searchTab"
            icon={
              currentTab == "searchTab" ? (
                <SearchIcon />
              ) : (
                <SearchOutlinedIcon />
              )
            }
          />
          <BottomNavigationAction
            label="Favoriten"
            value="favoriteTab"
            icon={
              currentTab == "favoriteTab" ? (
                <FavoriteIcon />
              ) : (
                <FavoriteBorderIcon />
              )
            }
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
