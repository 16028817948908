import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "./DrawerHeader";
import { Box } from "@mui/material";
import BlockedList from "./BlockedList";

export default function BlockedDrawer({
  openBlockedDrawer,
  toggleBlockedDrawer,
  setIgnoredCards,
  ignoredCards,
}) {
  return (
    <Drawer anchor="left" open={openBlockedDrawer}>
      <DrawerHeader
        menuName="Geblockt"
        toggleDrawer={toggleBlockedDrawer}
      ></DrawerHeader>
      <Box
        sx={{
          maxWidth: 450,
          width: "100vw",
          height: "100vh",
          backgroundColor: "primary.light",
        }}
        role="presentation"
      >
        <BlockedList
          data="ignoredCards"
          ignoredCards={ignoredCards}
          setIgnoredCards={setIgnoredCards}
        ></BlockedList>
      </Box>
    </Drawer>
  );
}
